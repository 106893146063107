<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container bg-white">
        <div class="row rounded-lg overflow-hidden shadow">
          <!-- Users box-->
          <div class="col-lg-5 px-0">
            <div class="bg-white">
              <div class="mb-5">
                <!-- <v-btn
          color="red lighten-2"
          dark
        >
          New Message
        </v-btn> -->
                <v-dialog v-model="dialog" width="700">
                  <div class="modal-content">
                    <!--begin::Form-->
                    <form id="kt_inbox_compose_form">
                      <!--begin::Header-->
                      <div
                        class="d-flex align-items-center justify-content-between py-5 pl-8 pr-5 border-bottom"
                      >
                        <h5 class="font-weight-bold m-0">Compose</h5>
                        <div class="d-flex ml-2">
                          <span class="btn btn-clean btn-sm btn-icon mr-2">
                            <i class="flaticon2-arrow-1 icon-1x"></i>
                          </span>
                          <span
                            class="btn btn-clean btn-sm btn-icon"
                            @click="dialog = false"
                          >
                            <i class="ki ki-close icon-1x"></i>
                          </span>
                        </div>
                      </div>
                      <!--end::Header-->
                      <!--begin::Body-->
                      <div class="d-block">
                        <!--begin::To-->
                        <div
                          class="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px"
                        >
                          <div class="text-dark-50 w-75px">To:</div>
                          <!-- <v-autocomplete
        v-model="company"
        :items="companies"
        :search-input.sync="search"
		@change="companySelected"
        color="white"
        hide-no-data
        hide-selected 
        item-text="company_name"
        item-value="id"
       
        placeholder="Choose Company to Message"
        return-object
      ></v-autocomplete> -->
                        </div>
                        <!--end::To-->

                        <!--begin::Subject-->
                        <div class="border-bottom">
                          <input
                            class="form-control border-0 px-8 min-h-45px"
                            name="compose_subject"
                            placeholder="Subject"
                          />
                        </div>
                        <!--end::Subject-->
                        <!--begin::message-->

                        <ckeditor
                          v-model="message"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>

                        <!--begin::Attachments-->
                        <div
                          id="kt_inbox_compose_attachments"
                          class="dropzone dropzone-multi px-8 py-4"
                        >
                          <div class="dropzone-items"></div>
                          <div class="dz-default dz-message">
                            <button class="dz-button" type="button">
                              Drop files here to upload
                            </button>
                          </div>
                        </div>
                        <!--end::Attachments-->
                      </div>
                      <!--end::Body-->
                      <!--begin::Footer-->
                      <div
                        class="d-flex align-items-center justify-content-between py-5 pl-8 pr-5 border-top"
                      >
                        <!--begin::Actions-->
                        <div class="d-flex align-items-center mr-3">
                          <!--begin::Send-->
                          <div class="btn-group mr-4">
                            <v-btn color="primary" text @click="dialog = false">
                              Cancel
                            </v-btn>
                            <span class="btn btn-primary font-weight-bold px-6"
                              >Send</span
                            >
                          </div>
                          <!--end::Send-->
                          <!--begin::Other-->
                          <span
                            id="kt_inbox_compose_attachments_select"
                            class="btn btn-icon btn-sm btn-clean mr-2 dz-clickable"
                          >
                            <i class="flaticon2-clip-symbol"></i>
                          </span>

                          <!--end::Other-->
                        </div>
                        <!--end::Actions-->
                      </div>
                      <!--end::Footer-->
                    </form>
                    <!--end::Form-->
                  </div>
                </v-dialog>
              </div>

              <v-card>
                <v-toolbar flat>
                  <v-toolbar-title class="text-dark">Inboxes</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-tabs v-model="active_tab" horizontal>
                  <v-tab>
                    <v-icon left>mdi-account</v-icon>
                    Partners
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-account</v-icon>
                    Public
                  </v-tab>

                  <v-tab-item>
                    <v-card>
                      <v-card-text>
                        <!--begin:Search-->
                        <div class="input-group input-group-solid mt-4 mb-4">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <path
                                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                      opacity="0.3"
                                    ></path>
                                    <path
                                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                    ></path>
                                  </g>
                                </svg>
                                <!--end::Svg Icon-->
                              </span>
                            </span>
                          </div>
                          <input
                            v-model="partner_search_key"
                            type="text"
                            class="form-control py-4 h-auto"
                            placeholder="Search by Company Name"
                          />
                        </div>
                      </v-card-text>
                      <!--end:Search-->
                    </v-card>
                    <div v-if="partnerList.length > 0" class="messages-box">
                      <div class="list-group rounded-0">
                        <div
                          v-for="(partner, index) in partnerList"
                          :key="index"
                        >
                          <a
                            v-if="
                              partner.supplier_company.id == active_supplier_id
                            "
                            class="list-group-item list-group-item-action active text-white rounded-0"
                            @click="
                              $router.push(
                                '/seller/messaging/thread/' +
                                  partner.supplier_company.id
                              )
                            "
                          >
                            <div class="media">
                              <span class="card-icon"
                                ><i
                                  class="flaticon-users-1 icon-2x text-white"
                                ></i
                              ></span>
                              <div class="media-body ml-4">
                                <div
                                  class="d-flex align-items-center justify-content-between mb-1"
                                >
                                  <h6 class="mb-0">
                                    {{
                                      partner.supplier_company.profile
                                        .profile_company_name
                                    }}
                                    <v-badge
                                      v-if="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ] != undefined
                                      "
                                      :content="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ]
                                      "
                                      :value="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ]
                                      "
                                      color="red"
                                      style="padding-left: 20px;"
                                    >
                                      <template #badge>
                                        <span>{{
                                          unreadchatlogs[
                                            partner.supplier_company.id
                                          ]
                                        }}</span>
                                      </template>
                                    </v-badge>
                                  </h6>
                                  <small
                                    v-if="
                                      lastchatlogs[
                                        partner.supplier_company.id
                                      ] != undefined
                                    "
                                    class="small font-weight-bold"
                                    >{{
                                      lastchatlogs[partner.supplier_company.id]
                                        .date | month_format
                                    }}</small
                                  >
                                </div>
                                <p
                                  v-if="
                                    lastchatlogs[partner.supplier_company.id] !=
                                      undefined
                                  "
                                  class="font-italic text-white mb-0 text-small"
                                >
                                  {{
                                    lastchatlogs[partner.supplier_company.id]
                                      .message
                                  }}
                                </p>
                              </div>
                            </div>
                          </a>
                          <a
                            v-else
                            class="list-group-item list-group-item-action list-group-item-light rounded-0"
                            @click="
                              $router.push(
                                '/seller/messaging/thread/' +
                                  partner.supplier_company.id
                              )
                            "
                          >
                            <div class="media">
                              <span class="card-icon"
                                ><i
                                  class="flaticon-users-1 icon-2x text-forest"
                                ></i
                              ></span>
                              <div class="media-body ml-4">
                                <div
                                  class="d-flex align-items-center justify-content-between mb-1"
                                >
                                  <h6 class="mb-0">
                                    {{
                                      partner.supplier_company.profile
                                        .profile_company_name
                                    }}
                                    <v-badge
                                      v-if="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ] != undefined
                                      "
                                      :content="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ]
                                      "
                                      :value="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ]
                                      "
                                      color="red"
                                      style="padding-left: 20px;"
                                    >
                                      <template #badge>
                                        <span>{{
                                          unreadchatlogs[
                                            partner.supplier_company.id
                                          ]
                                        }}</span>
                                      </template>
                                    </v-badge>
                                  </h6>
                                  <small
                                    v-if="
                                      lastchatlogs[
                                        partner.supplier_company.id
                                      ] != undefined
                                    "
                                    class="small font-weight-bold"
                                    >{{
                                      lastchatlogs[partner.supplier_company.id]
                                        .date | month_format
                                    }}</small
                                  >
                                </div>
                                <p
                                  v-if="
                                    lastchatlogs[partner.supplier_company.id] !=
                                      undefined
                                  "
                                  class="font-italic text-muted mb-0 text-small"
                                >
                                  {{
                                    lastchatlogs[partner.supplier_company.id]
                                      .message
                                  }}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card>
                      <v-card-text>
                        <!--begin:Search-->
                        <div class="input-group input-group-solid mt-4 mb-4">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <path
                                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                      opacity="0.3"
                                    ></path>
                                    <path
                                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                    ></path>
                                  </g>
                                </svg>
                                <!--end::Svg Icon-->
                              </span>
                            </span>
                          </div>
                          <input
                            v-model="public_search_key"
                            type="text"
                            class="form-control py-4 h-auto"
                            placeholder="Search by Company Name"
                          />
                        </div>
                      </v-card-text>
                      <!--end:Search-->
                    </v-card>
                    <div v-if="publicList.length > 0" class="messages-box">
                      <div class="list-group rounded-0">
                        <div
                          v-for="(partner, index) in publicList"
                          :key="index"
                        >
                          <a
                            v-if="
                              partner.supplier_company.id == active_supplier_id
                            "
                            class="list-group-item list-group-item-action active text-white rounded-0"
                            @click="
                              $router.push(
                                '/seller/messaging/thread/' +
                                  partner.supplier_company.id
                              )
                            "
                          >
                            <div class="media">
                              <span class="card-icon"
                                ><i
                                  class="flaticon-users-1 icon-2x text-white"
                                ></i
                              ></span>
                              <div class="media-body ml-4">
                                <div
                                  class="d-flex align-items-center justify-content-between mb-1"
                                >
                                  <h6 class="mb-0">
                                    {{
                                      partner.supplier_company.profile
                                        .profile_company_name
                                    }}
                                    <v-badge
                                      v-if="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ] != undefined
                                      "
                                      :content="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ]
                                      "
                                      :value="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ]
                                      "
                                      color="red"
                                      style="padding-left: 20px;"
                                    >
                                      <template #badge>
                                        <span>{{
                                          unreadchatlogs[
                                            partner.supplier_company.id
                                          ]
                                        }}</span>
                                      </template>
                                    </v-badge>
                                  </h6>
                                  <small
                                    v-if="
                                      lastchatlogs[
                                        partner.supplier_company.id
                                      ] != undefined
                                    "
                                    class="small font-weight-bold"
                                    >{{
                                      lastchatlogs[partner.supplier_company.id]
                                        .date | month_format
                                    }}</small
                                  >
                                </div>
                                <p
                                  v-if="
                                    lastchatlogs[partner.supplier_company.id] !=
                                      undefined
                                  "
                                  class="font-italic text-white mb-0 text-small"
                                >
                                  {{
                                    lastchatlogs[partner.supplier_company.id]
                                      .message
                                  }}
                                </p>
                              </div>
                            </div>
                          </a>
                          <a
                            v-else
                            class="list-group-item list-group-item-action list-group-item-light rounded-0"
                            @click="
                              $router.push(
                                '/seller/messaging/thread/' +
                                  partner.supplier_company.id
                              )
                            "
                          >
                            <div class="media">
                              <span class="card-icon"
                                ><i
                                  class="flaticon-users-1 icon-2x text-forest"
                                ></i
                              ></span>
                              <div class="media-body ml-4">
                                <div
                                  class="d-flex align-items-center justify-content-between mb-1"
                                >
                                  <h6 class="mb-0">
                                    {{
                                      partner.supplier_company.profile
                                        .profile_company_name
                                    }}
                                    <v-badge
                                      v-if="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ] != undefined
                                      "
                                      :content="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ]
                                      "
                                      :value="
                                        unreadchatlogs[
                                          partner.supplier_company.id
                                        ]
                                      "
                                      color="red"
                                      style="padding-left: 20px;"
                                    >
                                      <template #badge>
                                        <span>{{
                                          unreadchatlogs[
                                            partner.supplier_company.id
                                          ]
                                        }}</span>
                                      </template>
                                    </v-badge>
                                  </h6>
                                  <small
                                    v-if="
                                      lastchatlogs[
                                        partner.supplier_company.id
                                      ] != undefined
                                    "
                                    class="small font-weight-bold"
                                    >{{
                                      lastchatlogs[partner.supplier_company.id]
                                        .date | month_format
                                    }}</small
                                  >
                                </div>
                                <p
                                  v-if="
                                    lastchatlogs[partner.supplier_company.id] !=
                                      undefined
                                  "
                                  class="font-italic text-muted mb-0 text-small"
                                >
                                  {{
                                    lastchatlogs[partner.supplier_company.id]
                                      .message
                                  }}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </div>
          </div>
          <!-- Chat Box-->
          <div class="col-lg-7 " @click="setReadMessage">
            <v-toolbar class="blue-grey darken-2">
              <v-toolbar-title class="text-white">{{
                supplier_companyname
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                v-if="active_supplier_id != 0"
                color="primary"
                @click="
                  $router.push('/seller/supplier-detail/' + active_supplier_id)
                "
              >
                View Profile
              </v-btn>
            </v-toolbar>

            <div
              id="chat_div"
              class="px-4 py-5 chat-box bg-white"
              style="max-height: 60vh; overflow-y: scroll;"
            >
              <div v-for="(chat, id) in chats" :key="id">
                <!-- Reciever Message-->
                <div v-if="chat.is_seller == 1" class="media w-50 ml-auto mb-3">
                  <div class="media-body">
                    <p class="medium "></p>
                    <div class="bg-light rounded py-2 px-3 mb-2">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <p class="text-small mb-0 text-dark">
                              {{ chat.message }}
                            </p>
                          </span>
                        </template>
                        <span
                          ><p class="medium ">
                            {{ chat.sender }}
                            <span class="text-white">
                              {{ chat.date | date_format }}
                            </span>
                          </p>
                        </span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>

                <!-- Sender Message-->
                <template v-else>
                  <div class="media w-50 ml-3 mb-3">
                    <span class="card-icon"
                      ><i class="flaticon-users-1 icon-2x text-forest"></i
                    ></span>
                    <div class="media-body">
                      <p class="medium "></p>
                      <div class="bg-light rounded py-2 px-3 mb-2">
                        <p class="text-small mb-0 ">{{ chat.message }}</p>
                      </div>
                      <p class="medium ">
                        {{ chat.sender }}
                        <span class="text-muted">
                          {{ chat.date | date_format }}
                        </span>
                      </p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <v-card class="mx-3 mt-3">
              <v-card-text>
                <!-- Typing area -->
                <form
                  class="bg-white"
                  method="post"
                  @submit.prevent="sendMessage"
                >
                  <div class="input-group mt-3">
                    <i class="flaticon2-file icon-lg"></i>
                    <input
                      v-model="message"
                      type="text"
                      placeholder="Type a message"
                      aria-describedby="button-addon2"
                      class="form-control rounded-0 border-0 py-4 bg-white"
                      @keyup.enter="sendMessage"
                    />
                    <div class="input-group-append">
                      <button
                        id="button-addon2"
                        type="submit"
                        class="btn btn-link"
                      >
                        <i class="fa fa-paper-plane"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import gql from "graphql-tag";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("hh:mm A MM/DD/YYYY");
      }
    },
    month_format: function(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
  },
  data() {
    return {
      receiver_email: "",
      unreadchatlogs: [],
      lastchatlogs: [],
      active_tab: 0,
      active_supplier_id: 0,
      seller_companyname: "",
      supplier_companyname: "",
      partner_search_key: "",
      public_search_key: "",
      message: "",
      dialog: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },
  apollo: {
    // countUnreadChats: {
    //   query: gql`
    //     query CountUnreadChatsQuery($seller_id: Int!) {
    //       countUnreadChats: countunreadsellerchats(seller_id: $seller_id) {
    //         cnt
    //         seller_id
    //         supplier_id
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       seller_id: parseInt(this.$store.state.auth.user.company_relation),
    //     };
    //   },
    //   client: "chatClient",
    // },
    // lastChats: {
    //   query: gql`
    //     query LastChatsQuery($seller_id: Int!) {
    //       lastChats: lastsellerchats(seller_id: $seller_id) {
    //         id
    //         seller_id
    //         supplier_id
    //         message
    //         sender
    //         date
    //         is_seller
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       seller_id: parseInt(this.$store.state.auth.user.company_relation),
    //     };
    //   },
    //   client: "chatClient",
    // },
    // chats: {
    //   query: gql`
    //     query ChatsQuery($supplier_id: Int!, $seller_id: Int!) {
    //       chats(supplier_id: $supplier_id, seller_id: $seller_id) {
    //         id
    //         seller_id
    //         supplier_id
    //         message
    //         sender
    //         date
    //         is_seller
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       seller_id: parseInt(this.$store.state.auth.user.company_relation),
    //       supplier_id: parseInt(this.active_supplier_id),
    //     };
    //   },
    //   subscribeToMore: {
    //     document: gql`
    //       subscription MessageSentSubscription {
    //         messageSent {
    //           id
    //           seller_id
    //           supplier_id
    //           message
    //           sender
    //           date
    //           is_seller
    //         }
    //       }
    //     `,
    //     updateQuery: (previousData, { subscriptionData }) => {
    //       return {
    //         chats: [...previousData.chats, subscriptionData.data.messageSent],
    //       };
    //     },
    //   },
    //   client: "chatClient",
    // },
    partnerList: {
      query: gql`
        query Contacts($seller_company: String, $search: String) {
          partnerList: contacts(
            where: {
              status: "partner"
              seller_company: { id: $seller_company }
              supplier_company: {
                profile: { profile_company_name_contains: $search }
              }
            }
          ) {
            id
            status
            supplier_company {
              id
              profile {
                profile_company_name
                profile_contact_email
              }
            }
          }
        }
      `,
      variables() {
        return {
          seller_company: parseInt(
            this.$store.state.auth.user.company_relation
          ),
          search: this.partner_search_key,
        };
      },
    },
    publicList: {
      query: gql`
        query Contacts($seller_company: String, $search: String) {
          publicList: contacts(
            where: {
              status: "public"
              seller_company: { id: $seller_company }
              supplier_company: {
                profile: { profile_company_name_contains: $search }
              }
            }
          ) {
            id
            status
            supplier_company {
              id
              profile {
                profile_company_name
                profile_contact_email
              }
            }
          }
        }
      `,
      variables() {
        return {
          seller_company: parseInt(
            this.$store.state.auth.user.company_relation
          ),
          search: this.public_search_key,
        };
      },
    },
  },

  watch: {
    chats() {
      setTimeout(() => {
        this.scrollToEnd();
        this.updateLastChats();
      }, 500);
      setTimeout(() => {
        this.updateCountChats();
      }, 1000);
    },
    $route() {
      if (this.$route.params.id != "" && this.$route.params.id != undefined) {
        this.active_supplier_id = this.$route.params.id;
        this.updateCompanyName();
        this.scrollToEnd();
        this.setReadMessage();
      }
    },
  },
  mounted() {
    if (this.$route.params.id != "" && this.$route.params.id != undefined) {
      this.active_supplier_id = this.$route.params.id;

      setTimeout(() => {
        this.$apollo.queries.chats.refetch();
        this.setReadMessage();
      }, 1000);
    }
    setTimeout(() => {
      this.scrollToEnd();
      this.updateCompanyName();
      this.updateLastChats();
      this.updateCountChats();

      this.getContactInfo({
        supplier_company: this.active_supplier_id,
        seller_company: this.$store.state.auth.user.company_relation,
      }).then((contact) => {
        if (contact.data.length == 0) {
          return;
        } else {
          if (contact.data[0].status == "public") {
            this.active_tab = 1;
          } else {
            this.active_tab = 0;
          }
        }
      });
    }, 1000);
  },
  methods: {
    ...mapActions([
      "getContactInfo",
      "getContactTotalCount",
      "submitContact",
      "updateContact",
      "getCompanyInfo",
      "getApplicationInfo",
      "submitApplication",
    ]),
    async setReadMessage() {
      if (this.active_supplier_id != 0) {
        // await this.$apollo.mutate({
        //   client: "chatClient",
        //   mutation: gql`
        //     mutation UpdateReadMutation($seller_id: Int!, $supplier_id: Int!) {
        //       updateSellerRead(
        //         seller_id: $seller_id
        //         supplier_id: $supplier_id
        //       ) {
        //         seller_id
        //         supplier_id
        //       }
        //     }
        //   `,
        //   variables: {
        //     supplier_id: parseInt(this.active_supplier_id),
        //     seller_id: parseInt(this.$store.state.auth.user.company_relation),
        //   },
        // });
        this.updateCountChats();
      }
    },
    async updateLastChats() {
      let res = await this.$apollo.queries.lastChats.refetch();
      this.lastchatlogs = [];
      for (let i in res.data.lastChats) {
        this.lastchatlogs[res.data.lastChats[i].supplier_id] = [];
        this.lastchatlogs[res.data.lastChats[i].supplier_id]["message"] =
          res.data.lastChats[i].message;
        this.lastchatlogs[res.data.lastChats[i].supplier_id]["date"] =
          res.data.lastChats[i].date;
      }
    },
    async updateCountChats() {
      let res = await this.$apollo.queries.countUnreadChats.refetch();
      this.unreadchatlogs = [];
      for (let i in res.data.countUnreadChats) {
        this.unreadchatlogs[res.data.countUnreadChats[i].supplier_id] =
          res.data.countUnreadChats[i].cnt;
      }
    },
    scrollToEnd() {
      var chat_div = document.querySelector("#chat_div");
      if (chat_div != null) {
        chat_div.scrollTop = chat_div.scrollHeight;
      }
    },
    updateCompanyName() {
      if (this.active_supplier_id != 0) {
        this.getApplicationInfo({
          seller_company: this.$store.state.auth.user.company_relation,
          supplier_company: this.active_supplier_id,
        }).then((res) => {
          if (res.data.length == 0) {
            return;
          } else {
            this.supplier_companyname =
              res.data[0].supplier_company.company_name;
            this.seller_companyname = res.data[0].seller_company.company_name;
            this.getCompanyInfo({
              id: this.active_supplier_id,
            }).then((res) => {
              let emails = [];
              for (let i in res.data.company_users) {
                if (res.data.company_users[i].get_notice == true) {
                  emails.push(res.data.company_users[i].email);
                }
              }
              this.receiver_email = emails.join("-");
            });
          }
        });
      }
    },
    async sendMessage() {
      const message = this.message;
      this.message = "";

      if (message != "" && this.active_supplier_id != 0) {
        // await this.$apollo.mutate({
        //   client: "chatClient",
        //   mutation: gql`
        //     mutation SendMessageMutation(
        //       $seller_id: Int!
        //       $supplier_id: Int!
        //       $message: String!
        //       $sender: String!
        //       $receiver_email: String!
        //     ) {
        //       sendMessage(
        //         seller_id: $seller_id
        //         supplier_id: $supplier_id
        //         message: $message
        //         sender: $sender
        //         receiver_email: $receiver_email
        //         is_seller: 1
        //       ) {
        //         id
        //         seller_id
        //         supplier_id
        //         message
        //         sender
        //         date
        //         is_seller
        //       }
        //     }
        //   `,
        //   variables: {
        //     seller_id: parseInt(this.$store.state.auth.user.company_relation),
        //     supplier_id: parseInt(this.active_supplier_id),
        //     message,
        //     sender:
        //       this.$store.state.auth.user.FirstName +
        //       " " +
        //       this.$store.state.auth.user.LastName,
        //     receiver_email: this.receiver_email,
        //   },
        // });
        this.scrollToEnd();
        this.updateLastChats();
      }
    },
  },
};
</script>
<style scoped>
.text-forest {
  color: #455a64 !important;
}
.blue-grey {
  background-color: #455a64 !important;
  border-color: #455a64 !important;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #455a64;
  border-color: #455a64;
}
</style>

<style lang="scss">
.img-raised {
  -webkit-box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.54),
    0 8px 10px -5px rgba(0, 0, 0, 0.7);
}

.md-card-pricing ul {
  list-style: none;
  padding: 0;
  max-width: 240px;
  margin: 10px auto;
}
.md-card-pricing ul li {
  color: #fff;
  text-align: center;
  padding: 12px 0;
  font-size: 1.5rem;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
}

.follow {
  position: absolute;
  top: -28px;
  right: 7px;
}

.lato-header {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
  font-size: 4rem;
  font-weight: 800;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  text-shadow: #ccc;
}

.lato {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
}
.section-text {
  p {
    font-size: 1.188rem;
    line-height: 1.5em;
    color: #333;
    margin-bottom: 30px;
  }
  .blockquote {
    p {
      font-size: 1.5rem;
    }
  }
}

.section-blog-info {
  padding-top: 30px;
  padding-bottom: 0;

  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
  .blog-tags {
    padding-top: 8px;
  }
  .card-profile {
    margin-top: 0;
    text-align: left;

    .description {
      font-size: 18px;
    }
    .btn {
      margin-top: 25px;
    }
  }
}
.section-comments {
  .title {
    margin-bottom: 30px;
  }
}

.comment {
  p {
    color: #ccc;
  }
}

.section-projects-1 {
  .title {
    margin-top: 10px;
  }
}

.section-projects-2 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .description {
    margin-bottom: 130px;
  }
  .card-title {
    margin-bottom: 5px;
  }
  .category {
    color: #ccc;
    margin-top: 5px;
  }
  .card-description {
    margin-top: 20px;
  }
  .md-card {
    text-align: center;
  }
}

.section-projects-3 {
  .title {
    margin-bottom: 80px;
    margin-top: 5px;
  }

  .md-card {
    margin-bottom: 30px;
    margin-top: 60px;
    text-align: center;
  }
  .category {
    margin-bottom: 5px;
  }
  .card-title {
    margin-top: 5px;
  }
}

.section-projects-4 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .title + .description {
    margin-bottom: 130px;
  }

  .info {
    padding: 0;

    p {
      margin: 0;
    }
  }

  .md-card-background {
    margin-bottom: 0;

    .md-card-content {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }
  hr {
    margin: 70px auto;
    max-width: 970px;
  }
  .md-card {
    margin-top: 0;
  }
}

.back-background .card-title,
.front-background .card-title,
.md-card-background .card-title {
  color: #ffffff !important;
  margin-top: 10px;
}

.section-projects-1 {
  .title {
    margin-top: 10px;
  }
}

.section-projects-2 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .description {
    margin-bottom: 130px;
  }
  .card-title {
    margin-bottom: 5px;
  }
  .category {
    color: #ccc;
    margin-top: 5px;
  }
  .card-description {
    margin-top: 20px;
  }
  .md-card {
    text-align: center;
  }
}

.section-projects-3 {
  .title {
    margin-bottom: 80px;
    margin-top: 5px;
  }

  .md-card {
    margin-bottom: 30px;
    margin-top: 60px;
    text-align: center;
  }
  .category {
    margin-bottom: 5px;
  }
  .card-title {
    margin-top: 5px;
  }
}

.section-projects-4 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .title + .description {
    margin-bottom: 130px;
  }

  .info {
    padding: 0;

    p {
      margin: 0;
    }
  }

  .md-card-background {
    margin-bottom: 0;

    .md-card-content {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }
  hr {
    margin: 70px auto;
    max-width: 970px;
  }
  .md-card {
    margin-top: 0;
  }
}

.skew-separator {
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 800px;
    -webkit-transform: skewY(-8deg);
    transform: skewY(-8deg);
    background: #fff;
    top: 88%;
    z-index: 1;
  }

  &.skew-top {
    &:after {
      height: 370px !important;
      top: -220px !important;
    }
  }

  &.skew-mini {
    &:after {
      top: 79% !important;
    }
  }
}
.section-shaped {
  position: relative;
  overflow: hidden;

  &.section-hero {
    &:before {
      top: 680px;
    }
  }

  .stars-and-coded {
    margin-top: 8rem;
  }

  .shape {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
    }

    + .container {
      position: relative;
      // padding-top: 6rem;
      // padding-bottom: 0rem;
      height: 100%;
    }

    &.shape-skew {
      + .container {
        padding-top: 0;

        .col {
          margin-top: -100px;
        }
      }

      + .shape-container {
        padding-top: 18rem;
        padding-bottom: 19rem;
      }
    }
  }

  .shape-skew {
    transform: skewY(-4deg);
    transform-origin: 0;

    span {
      transform: skew(4deg);
    }
  }

  // Color variations
  .shape-primary {
    background: linear-gradient(
      150deg,
      shapes-primary-color("step-1-gradient-bg") 15%,
      shapes-primary-color("step-2-gradient-bg") 70%,
      shapes-primary-color("step-3-gradient-bg") 94%
    );

    :nth-child(1) {
      background: shapes-primary-color("span-1-bg");
    }

    :nth-child(2) {
      background: shapes-primary-color("span-2-bg");
    }

    :nth-child(3) {
      background: shapes-primary-color("span-3-bg");
    }

    :nth-child(4) {
      background: shapes-primary-color("span-4-bg");
    }

    :nth-child(5) {
      background: shapes-primary-color("span-5-bg");
    }
  }

  .shape-default {
    background: linear-gradient(
      150deg,
      shapes-default-color("step-1-gradient-bg") 15%,
      shapes-default-color("step-2-gradient-bg") 70%,
      shapes-default-color("step-3-gradient-bg") 94%
    );

    :nth-child(1) {
      background: shapes-default-color("span-1-bg");
    }

    :nth-child(2) {
      background: shapes-default-color("span-2-bg");
    }

    :nth-child(3) {
      background: shapes-default-color("span-3-bg");
    }

    :nth-child(4) {
      background: shapes-default-color("span-4-bg");
    }

    :nth-child(5) {
      background: shapes-default-color("span-5-bg");
    }
  }

  .shape-light {
    background: linear-gradient(
      150deg,
      shapes-light-color("step-1-gradient-bg") 15%,
      shapes-light-color("step-2-gradient-bg") 70%,
      shapes-light-color("step-3-gradient-bg") 94%
    );

    :nth-child(1) {
      background: shapes-light-color("span-1-bg");
    }

    :nth-child(2) {
      background: shapes-light-color("span-2-bg");
    }

    :nth-child(3) {
      background: shapes-light-color("span-3-bg");
    }

    :nth-child(4) {
      background: shapes-light-color("span-4-bg");
    }

    :nth-child(5) {
      background: shapes-light-color("span-5-bg");
    }
  }

  .shape-dark {
    background: linear-gradient(
      150deg,
      shapes-dark-color("step-1-gradient-bg") 15%,
      shapes-dark-color("step-2-gradient-bg") 70%,
      shapes-dark-color("step-3-gradient-bg") 94%
    );

    :nth-child(1) {
      background: shapes-dark-color("span-1-bg");
    }

    :nth-child(2) {
      background: shapes-dark-color("span-2-bg");
    }

    :nth-child(3) {
      background: shapes-dark-color("span-3-bg");
    }

    :nth-child(4) {
      background: shapes-dark-color("span-4-bg");
    }

    :nth-child(5) {
      background: shapes-dark-color("span-5-bg");
    }
  }

  .shape-style-1 {
    span {
      height: 120px;
      width: 120px;
      border-radius: 50%;
    }

    .span-200 {
      height: 200px;
      width: 200px;
    }

    .span-150 {
      height: 150px;
      width: 150px;
    }

    .span-100 {
      height: 100px;
      width: 100px;
    }

    .span-75 {
      height: 75px;
      width: 75px;
    }

    .span-50 {
      height: 50px;
      width: 50px;
    }

    :nth-child(1) {
      left: -4%;
      bottom: auto;
      background: rgba(255, 255, 255, 0.1);
    }

    :nth-child(2) {
      right: 4%;
      top: 10%;
      background: rgba(255, 255, 255, 0.1);
    }

    :nth-child(3) {
      top: 280px;
      right: 5.66666%;
      background: rgba(255, 255, 255, 0.3);
    }

    :nth-child(4) {
      top: 320px;
      right: 7%;
      background: rgba(255, 255, 255, 0.15);
    }

    :nth-child(5) {
      top: 38%;
      left: 1%;
      right: auto;
      background: rgba(255, 255, 255, 0.05);
    }

    :nth-child(6) {
      width: 200px;
      height: 200px;
      top: 44%;
      left: 10%;
      right: auto;
      background: rgba(255, 255, 255, 0.15);
    }

    :nth-child(7) {
      bottom: 50%;
      right: 36%;
      background: rgba(255, 255, 255, 0.04);
    }

    :nth-child(8) {
      bottom: 70px;
      right: 2%;
      background: rgba(255, 255, 255, 0.2);
    }

    :nth-child(9) {
      bottom: 1%;
      right: 2%;
      background: rgba(255, 255, 255, 0.1);
    }

    :nth-child(10) {
      bottom: 1%;
      left: 1%;
      right: auto;
      background: rgba(255, 255, 255, 0.05);
    }

    // shape color variations
    &.shape-primary {
      background: linear-gradient(
        150deg,
        shapes-primary-color("step-1-gradient-bg") 15%,
        shapes-primary-color("step-2-gradient-bg") 70%,
        shapes-primary-color("step-3-gradient-bg") 94%
      );
    }

    &.shape-default {
      background: linear-gradient(
        150deg,
        shapes-default-color("step-1-gradient-bg") 15%,
        shapes-default-color("step-2-gradient-bg") 70%,
        shapes-default-color("step-3-gradient-bg") 94%
      );
    }

    &.shape-light {
      background: linear-gradient(
        150deg,
        shapes-light-color("step-1-gradient-bg") 15%,
        shapes-light-color("step-2-gradient-bg") 70%,
        shapes-light-color("step-3-gradient-bg") 94%
      );
    }

    &.shape-dark {
      background: linear-gradient(
        150deg,
        shapes-dark-color("step-1-gradient-bg") 15%,
        shapes-dark-color("step-2-gradient-bg") 70%,
        shapes-dark-color("step-3-gradient-bg") 94%
      );
    }
  }

  // Styles - works with a color variation class (e.g shapes-default)
  .shape-style-2 {
    span {
      height: 190px;
    }

    .span-sm {
      height: 100px;
    }

    :nth-child(1) {
      width: 33.33333%;
      top: 0;
      left: -16.66666%;
    }

    :nth-child(2) {
      width: 33.33333%;
      top: 0;
      left: 16.66666%;
      right: auto;
    }

    :nth-child(3) {
      width: 33.33333%;
      left: 49.99999%;
      bottom: auto;
    }

    :nth-child(4) {
      width: 33.33333%;
      top: 55%;
      right: -16.66666%;
    }

    :nth-child(5) {
      width: 33.33333%;
      bottom: 0;
    }

    .shape-style-3 {
      span {
        height: 140px;
      }

      .span-sm {
        height: 100px;
      }

      :nth-child(1) {
        width: 66%;
        left: -16.66666%;
        bottom: auto;
      }

      :nth-child(2) {
        width: 40%;
        top: 54%;
        //height: 320px;
        right: -16.66666%;
      }

      :nth-child(3) {
        width: 33.33333%;
        top: 34%;
        left: -16.66666%;
        right: auto;
      }

      :nth-child(4) {
        width: 60%;
        bottom: 0;
        right: -16.66666%;
        opacity: 0.6;
      }

      :nth-child(5) {
        width: 33.33333%;
        bottom: 0;
      }
    }
  }
}
</style>

<style scoped>
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
}
.v-application .title {
  font-size: 2.75rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Lato", sans-serif;
}

.page-header {
  height: 100vh;
  background-position: 50%;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-raised {
  margin: -180px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.main {
  background: #eee;
  position: relative;
  z-index: 3;
}
.md-button:not(.md-just-icon):not(.md-btn-fab):not(.md-icon-button):not(.md-toolbar-toggle)
  .md-ripple {
  padding: 12px 30px;
}
.md-button .md-ripple {
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.md-ripple {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(circle, #fff 100%, #000 0);
}
</style>
