import { render, staticRenderFns } from "./Seller-messaging.vue?vue&type=template&id=34c018cb&scoped=true&"
import script from "./Seller-messaging.vue?vue&type=script&lang=js&"
export * from "./Seller-messaging.vue?vue&type=script&lang=js&"
import style0 from "./Seller-messaging.vue?vue&type=style&index=0&id=34c018cb&scoped=true&lang=css&"
import style1 from "./Seller-messaging.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./Seller-messaging.vue?vue&type=style&index=2&id=34c018cb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c018cb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCard,VCardText,VDialog,VIcon,VImg,VSpacer,VTab,VTabItem,VTabs,VToolbar,VToolbarTitle,VTooltip})
